import React from 'react';

const Standesamt = () => {
  return (
    <div className="outer-container">
      <div className="inner-container-centered">
        <p>
          <h1 className="my-heading-dancingscript">Standesamt</h1>
          <strong>Wir heiraten standesamtlich am <u>04.08.2025</u>.<br /><br /> </strong>
          Leider sind die Plätze im Standesamt begrenzt.<br /><br />
          Deswegen werden wir die Standesamtliche Trauung, am 04.08.2025 und anschließende kleine Feier im engsten Familien- und Freundeskreis abhalten. <br /><br />
          Hierfür erhaltet ihr eine explizite Einladung.
          <div className="image-container">
            <img src="/photos/standesamt_image_01.jpg" alt="White Spreelounge" className="responsive-image" />
          </div><br />
        </p>
      </div>
    </div>
  );
};

export default Standesamt;