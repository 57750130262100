import React, { useState, useEffect } from 'react';
import './Navbar.css';
import { Link } from 'react-router-dom';

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };


  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const [isMobile, setIsMobile] = useState(false);

  // Handle window resize event
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 1230);
  };

  console.log("isMobile: " + isMobile);

  return (
    <nav className="Navbar">
      {isMobile && (
        <div className="Navbar-container">
          <Link to="/" className="home-link" onClick={closeMenu}>
            Aline & Dominik
          </Link>
          <div className="burger-menu" onClick={toggleMenu}>
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
          </div>
          <ul className={`nav-links ${isOpen ? 'open' : ''}`}>
            <li>
              <Link to="/standesamt" onClick={closeMenu}>Standesamt</Link>
            </li>
            <li>
              <Link to="/freietrauung" onClick={closeMenu}>Freie Trauung</Link>
            </li>
            <li>
              <Link to="/anreise" onClick={closeMenu}>Anreise</Link>
            </li>
            <li>
              <Link to="/unterkunft" onClick={closeMenu}>Unterkunft</Link>
            </li>
            <li>
              <Link to="/in-der-woche" onClick={closeMenu}>In der Woche</Link>
            </li>
            {/* <li>
                    <Link to="/fotoupload" onClick={closeMenu}>Foto Upload</Link>
                  </li> */}
            <li>
              <Link to="/faq" onClick={closeMenu}>FAQ</Link>
            </li>
          </ul>
        </div>
      )
      }
      {!isMobile && (
        <div className="Navbar-container">
          <Link to="/" className="home-link" onClick={closeMenu}>
            Aline & Dominik
          </Link>
          <div className="burger-menu" onClick={toggleMenu}>
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
          </div>
          <ul className={`nav-links ${isOpen ? 'open' : ''}`}>
            <li><Link to="/standesamt" onClick={closeMenu}>Standesamt</Link></li>
            <li><Link to="/freietrauung" onClick={closeMenu}>Freie Trauung</Link></li>
            <li className="dropdown">
              <Link to="/anreise" onClick={toggleMenu}>Anreise</Link>
              <ul className="dropdown-menu">
              <li><Link to="/anreise/auto" onClick={toggleMenu}>Auto</Link></li>
              <li><Link to="/anreise/bahn" onClick={toggleMenu}>Zug</Link></li>
              <li><Link to="/anreise/flugzeug" onClick={toggleMenu}>Flugzeug</Link></li>
            </ul>
            </li>
            <li><Link to="/unterkunft" onClick={closeMenu}>Unterkunft</Link></li>
            <li className="dropdown">
            <Link to="/in-der-woche" onClick={toggleMenu}>In der Woche</Link>
            <ul className="dropdown-menu">
              <li><Link to="/in-der-woche/socializing" onClick={toggleMenu}>Get Together</Link></li>
              <li><Link to="/in-der-woche/berlintips" onClick={toggleMenu}>Tipps in Berlin</Link></li>
            </ul>
          </li>
            {/* <li><Link to="/fotoupload" onClick={closeMenu}>Foto Upload</Link></li> */}
            <li><Link to="/faq" onClick={closeMenu}>FAQ</Link></li>
          </ul>
        </div>
      )
      }

    </nav>
  );
}

export default Navbar;
