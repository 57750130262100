import React from 'react';

const Unterkunft = () => {
  return (
    <div className="outer-container">
      <div className="inner-container">
        <p>
          <h1 className="my-heading-dancingscript">Unterkünfte</h1>
          <div className="centered-textblock">
          Wir haben für euch ein paar Unterkünfte rausgesucht, die in der Nähe der Location der freien Trauung sind bzw. in der Nähe des Standesamts Köpenick und der Schlossbrauerei Köpenick (Treffpunkt unter der Woche).<br /><br />
          </div>
          {/* TODO add image with marks -> maybe try to embed gmaps */}
        </p>
        <p>
          <strong>Ibis Styles Berlin Treptow</strong> (<a href="https://all.accor.com/hotel/B626/index.de.shtml" target="_blank" rel="noopener noreferrer" class="text-link">Link</a>)<br />
          Spreestraße 14<br />
          12439 Berlin<br />
          (zu Fuß 5 Minuten von der Location für die Freie Trauung weg)<br />
        </p><br />
        <p>
          <strong>Hotel Berlin Köpenick by Leonardo Hotels</strong> (<a href="https://www.leonardo-hotels.de/berlin/hotel-berlin-kopenick-by-leonardo-hotels" target="_blank" rel="noopener noreferrer" class="text-link">Link</a>)<br />
          Grünauer Str.1<br />
          12557 Berlin<br />
          (zu Fuß 10 Minuten vom Standesamt und der Schlossbrauerei Köpenick weg)<br />
        </p><br />
        <p>
          <strong>Seminar- und Gästehaus Flussbad Gartenstraße</strong> (<a href="https://www.hotel-pension-berlin.eu/" target="_blank" rel="noopener noreferrer" class="text-link">Link</a>)<br />
          Gartenstraße 46-48<br />
          12557 Berlin<br />
          (zu Fuß 10-15 Minuten vom Standesamt und der Schlossbrauer Köpenick weg. Hat einen eigenen kleine Strand.)<br />
        </p><br />
        <p>
          <strong>Nena Appartments</strong> (<a href="https://www.nena-apartments.de/standorte/berlin/nena-adlershof/" target="_blank" rel="noopener noreferrer" class="text-link">Link</a>)<br />
          Dörpfeldstraße 37<br />
          12489 Berlin<br />
          (ist bei uns in Adlershof und nicht weit entfernt von unserer Wohnung. Unter den Appartments gibt es ein nettes Cafe und einen Edeka gegenüber. Die Tram vor der Tür fährt einen nach Köpenick oder direkt zur Location für die Freie Trauung)<br />
        </p><br />
        <p>
          <strong>Airbnb & Ferienwohnungen</strong><br />
          Es gibt auch zahlreiche airbnb's und Ferienwohnungen.<br />
          Beachtet bei eurer Wahl, dass Berlin sehr groß ist. Je weiter ihr vom Bezirk Treptow-Köpenick eure Unterkunft sucht, desto länger fahrt ihr natürlich auch. 45 - 60 Minuten Fahrtzeit müssen dann schonmal eingeplant werden.<br /><br />
        </p>
        <div className="image-container">
          <img src="/photos/unterkuenfte_image_01.jpeg" alt="White Spreelounge" className="responsive-image" />
        </div><br />
      </div>
    </div>
  );
};

export default Unterkunft;