import React from 'react';
import { Link } from 'react-router-dom';

//Inhalt
// Hotel & Guesthouses
// evtl. Google Maps integration
// Anreise Berlin allgemein (Bhf, Flughafen, Auto)
// 

const Anreise = () => {
  return (
    <div className="outer-container">
      <div className="inner-container">
        <h1 className="my-heading-dancingscript">Anreise nach Berlin</h1>
        Wir empfehlen euch eine Anreise via:
        <ul>
          <li><Link to="/anreise/auto" className='text-link'>Auto</Link></li>
          <li><Link to="/anreise/bahn" className='text-link'>Bahn</Link></li>
          <li><Link to="/anreise/flugzeug" className='text-link'>Flugzeug</Link></li>
        </ul><br/>
        <div className="image-container">
          <img src="/photos/anreise_image_01.jpg" alt="White Spreelounge" className="responsive-image" />
        </div>
      </div>
    </div>
  );
};

export default Anreise;
