import React from 'react';
// Inhalt:
//  Adresse
//  Bild Innenhof wo der eingang ist
//  Parksituation
//  Anreise Auto/Öffis

const FreieTrauung = () => {
  return (
    <div className="outer-container">
      <div className="inner-container">
        <h1 className="my-heading-dancingscript">Freie Trauung</h1>
        <div className="centered-textblock">
        <strong>
        Wir laden euch herzlich zu unserer Freien Trauung am <u>09.08.2025</u> ein!<br /><br />
        </strong>
        <div className="image-container">
            <img src="/photos/freie_trauung_image_01.jpg" alt="White Spreelounge" className="responsive-image" />
        </div><br />
        Diese findet im Lighthouse der White Spreelounge statt und befindet sich in der Nähe des S-Bahnhofs Schöneweide.<br /><br />
        Informationen zur Uhrzeit erhaltet ihr noch. Die Trauung und Feier wird hier stattfinden:<br /><br /><br />
        <strong>White Spreelounge - Lighthouse</strong><br />
          89 Lighthouse<br />
          Wilhelminenhofstraße 89<br />
          12459 Berlin<br />
        </div><br />

        <div className="image-container">
          <img src="images/white-spree-lounge_lighthouse-map.jpg" alt="White Spreelounge" className="responsive-image" />
        </div><br />


        <h3 className="my-heading">Anreise mit den öffentliche Verkehrsmittel</h3>

        Nehmt einen Zug bis S-Bahnhof Schöneweide (S45/S46/S47/S8/S85/S9).<br /><br />
        Von hier:

        <ul>
          <li>Zu Fuß: ca. 15 Minuten</li>
          <li>Per Bus: M17 (15 Minuten)</li>
          <li>Per Tram: momentan wird in Schöneweide gebaut. Sollte die Stadt Berlin ihre Bauarbeiten bis dahin beendet haben, informieren wir euch.</li>
        </ul><br />
        <h3 className="my-heading">Anreise mit dem Auto</h3>

        Die Spreelounge befindet sich in den Wilhelminenhöfen mit einem dazugehörigen Parkplatz. Auf diesen muss jedoch eine Parkscheibe genutzt und es darf <strong>nur für 1,5 Stunden</strong> geparkt werden. <br />
        Wer nicht immer wieder rauslaufen möchte, um die Parkscheibe weiterzudrehen, muss sich in der Umgebung einen Parkplatz suchen.

      </div>
    </div>
  );
};

export default FreieTrauung;