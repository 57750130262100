import React from 'react';

const AnreiseAuto = () => {
  return (
    <div className="outer-container">
      <div className="inner-container-centered">
        <h1 className="my-heading-dancingscript">Anreise per Auto</h1>
        <p>
          Treptow-Köpenick ist sehr gut über die Autobahn zu erreichen.
          <ul>
            <li>Aus NRW: A2-&gt;A10-&gt;A113 </li>
            <li>Aus dem Süden:  A9-&gt;A10-&gt;A113</li>
          </ul>
          Die Parkplatzsituation in Berlin ist teilweise katastrophal. Innerhalb von Berlin braucht ihr kein Auto bzw. würden wir euch raten, auf die öffentlichen Verkehrsmittel umzusteigen.
        </p><br />
        <div className="image-container">
          <img src="/photos/anreise_auto_image_01.jpg" alt="White Spreelounge" className="responsive-image" />
        </div>
      </div>
    </div>
  );
};

export default AnreiseAuto;
