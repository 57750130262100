import React from 'react';

function Home() {
    return (
        <div className="outer-container">
            <div className="inner-container-centered">
                <p>
                    <h1 className="my-welcome-heading">Herzlich Willkommen</h1>
                    {/* <div className='background-block'>
                        Keiner ist so verrückt,<br />
                        dass er nicht einen noch Verrückteren fände,<br />
                        der ihn versteht<br /><br />
                        <i>Heinrich-Heine</i>
                    </div><br/><br/> */}
                    {/* TODO Foto */}
                    <div className="image-container">
                        <img src="/photos/home_image_01.jpg" alt="White Spreelounge" className="responsive-image" />
                    </div><br />

                    <strong>
                    Es ist nicht zu fassen, aber wir werden heiraten!<br />
                    Wir laden euch herzlich ein dieses Ereignis mit uns zu feiern!<br /><br />
                    </strong>

                    Auf dieser Website geben wir euch alle wichtigen Informationen zu unserer Hochzeit.<br /><br />

                    Unsere Feier ist eine <strong>Adults-only</strong> Party.<br /><br />

                    Wer sich in die Planung mit einbringen möchte, Spiele planen oder Aktivitäten an der Hochzeit einbringen will, wende sich vertrauensvoll an unsere Trauzeugen:<br /><br />

                    <strong>
                        Adrianna Nagel<br />
                        Franziska Bachmann<br />
                        Clemens Bernemann<br />
                        Marco Steinmann<br /><br />
                    </strong>
                    {/* TODO: E-Mail */}
                    Wir freuen uns auf euch!<br /><br />
                    <div className='signature'>
                        <strong>
                            Aline & Dominik
                        </strong>
                    </div>
                </p>
            </div>
        </div>
    );
};


export default Home;
