import React from 'react';

const InDerWocheSocializing = () => {
    return (
        <div className="outer-container">
            <div className="inner-container">
                <h1 className="my-heading-dancingscript">Get Together & Socializing</h1>
                <p>
                    Wir freuen uns über jeden, der schon in der Woche anreisen und Berlin genießen möchte. Da unsere Wohnung nicht so groß ist, haben wir uns folgendes ausgedacht:<br /><br />
                    In der Altstadt Köpenick, befindet sich die Schlossplatzbrauerei, die kleinste Brauerei Deutschlands. Mit dem einem oder anderen waren wir auch schonmal da. Dies soll unser Treffpunkt werden.<br /><br />
                    Jeder der mag und Lust hat, kann abends zum Biergarten vorbeikommen. Kein Muss, aber die Möglichkeit sich entweder besser kennen zu lernen
                    oder alte Verbindungen neu zu stärken.<br /><br />
                    Wir werden nicht jeden Abend da sein, aber das braucht es wahrscheinlich auch nicht.<br /><br />
                    Von Tag zu Tag können wir uns neu verabreden.<br /><br />
                    <div className="image-container">
                        <img src="/photos/inderwoche_socializing_image01.jpeg" alt="White Spreelounge" className="responsive-image" />
                    </div>
                </p>
            </div>
        </div>
    );
};

export default InDerWocheSocializing;