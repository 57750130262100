import React from 'react';

const AnreiseBahn = () => {
  return (
    <div className="outer-container">
      <div className="inner-container-centered">
        <h1 className="my-heading-dancingscript">Anreise per Bahn</h1>
        <p>
          Berlin ist nicht nur gut mit der Deutschen Bahn zu erreichen, sondern auch mit dem Flixtrain, der sein Angebot immer wieder erweitert. Wer aus NRW kommt, dem ist, zum Beispiel, die Linie FLX30 zu empfehlen. Als Ziel gebt ihr am besten Ostbahnhof oder Südkreuz ein. Alternativ ist auch immer der Hauptbahnhof möglich, allerdings fährt man von hier bis Treptow Köpenick mindestens 50 Minuten.<br /><br />
          Aber egal, welche Haltestelle ihr angebt, es fährt immer eine S-Bahn durch bis Schöneweide, wo sich unsere Location befindet. Wir wohnen in Adlershof und unser Treffpunkt unter der Woche befindet sich in Köpenick. Und Google Maps ist in diesem Punkt auch immer noch der beste Helfer.
        <h2 className="my-heading-dancingscript">Tickets in Berlin</h2>
        <div className="image-container">
          <img src="/images/berlin_public_transport_annotated.png" alt="White Spreelounge" className="responsive-image" />
        </div><br/><br/>
          Die Tickets sind in Berlin recht günstig. Es gibt die Tarifbereiche A,B und C.<br/>

          Mit einem Ticket aus dem AB-Bereich könnt ihr in ganz Berlin herumfahren. Wollt ihr zum Flughafen, der in Brandenburg liegt, braucht ihr ein Zusatzticket für den C-Bereich. Hört sich kompliziert an, ist es aber nicht.  Tickets bekommt ihr an den Fahrkartenautomaten oder Online über die BVG bzw. DB-App. Hier könnt ihr eure Strecke eingeben und ihr bekommt in den meisten Fällen (außer ihr fahrt zum Flughafen) ein Ticket für den AB-Bereich. Das gilt auch wenn ihr nur von Köpenick bis Schöneweide fahrt.<br/><br/>
          
          Mögliche Tickets (AB-Bereich):
          <ul>
            <li>Einzelticket: 3,30 €</li>
            <li>24-Stunden-Karte: 9,90€</li>
            <li>24-Stunden-Karte (5Personen): 31 €</li>
            <li>7-Tage Karte: 41,50€</li>
          </ul>
        </p>
        <div className="image-container">
          <img src="/photos/anreise_zug_image_01.jpg" alt="White Spreelounge" className="responsive-image" />
        </div>
      </div>
    </div>
  );
};

export default AnreiseBahn;
