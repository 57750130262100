import React from 'react';

const AnreiseFlugzeug = () => {
  return (
    <div className="outer-container">
      <div className="inner-container-centered">
        <h1 className="my-heading-dancingscript">Anreise per Flugzeug</h1>
           <p>
          Unser Bezirk Treptow-Köpenick ist wunderbar vom Flughafen BER erreichbar. In knapp 20 Minuten fährt die S9/S45 bis zu uns nach Adlershof oder bis zur Location nach Schöneweide. Um nach Köpenick zu gelangen, kann man in Adlershof und Schöneweide in die Tram oder den Bus umsteigen.<br/><br/>
          Beachtet bitte, dass der Flughafen im Tarifgebiet C liegt, da er außerhalb von Berlin ist. So sind die Preise für die jeweiligen Tickets höher.
        </p><br/>
        <div className="image-container">
          <img src="/photos/anreise_flugzeug_image_01-a.jpg" alt="White Spreelounge" className="responsive-image" />
        </div>
      </div>
    </div>
  );
};

export default AnreiseFlugzeug;