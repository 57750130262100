import React from 'react';
import { Link } from 'react-router-dom';
//Inhalt:
// Socalising & GetTogether
// Kleinste Brauerei (Schlossplatz Brauerei, Hops & Barleys, Freiheit 15)
// Empfehlungen für Berlin (Dark Matters, Alex, Standbad Müggelsee, Strandbad Erkner, ...)
// Öffi Anreise


const InDerWoche = () => {
  return (
    <div className="outer-container">
      <div className="inner-container">
        <h1 className="my-heading-dancingscript">In der Woche</h1>
        In der Woche könnt ihr euch mit uns treffen oder mit folgenden Tipps euch die Zeit in Berlin vertreiben:
        <ul>
          <li><Link to="/in-der-woche/socializing" className='text-link'>Get Together</Link></li>
          <li><Link to="/in-der-woche/berlintips" className='text-link'>Tipps in Berlin</Link></li>
        </ul><br/>
        <div className="image-container">
          <img src="/photos/inderwoche_image_01.jpg" alt="White Spreelounge" className="responsive-image" />
        </div>
      </div>
    </div>
  );
};

export default InDerWoche;